import request from '@/http/request'
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config'

/** 获取url **/
export function getRedirectUrl () {
  return request.request({
    url: '/api/cashier/redirectUrl',
    method: 'POST',
    data: { token: config.cacheToken }
  })
}

/** 获取url **/
export function getChannelUserId (redirectData) {
  return request.request({
    url: '/api/cashier/channelUserId',
    method: 'POST',
    data: Object.assign({token: config.cacheToken }, redirectData)
  })
}

/** 获取聚合支付方式对应的支付方式列表 **/
export function getPayWayList (redirectData) {
  return request.request({
    url: '/api/cashier/payWayList',
    method: 'POST',
    data: Object.assign({token: config.cacheToken }, redirectData)
  })
}

/** 聚合页面中选择具体支付方式是，更新订单信息 **/
export function updateOrder(wayCode) {
  return request.request({
    url: '/api/cashier/updateOrder',
    method: 'POST',
    data: { token: config.cacheToken, wayCode: wayCode }
  })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage (amount) {
  return request.request({
    url: '/api/cashier/pay',
    method: 'POST',
    data: {
      token: config.cacheToken,
      amount: amount,
      channelUserId: channelUserIdUtil.getChannelUserId()
    }
  })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo () {
  return request.request({
    url: '/api/cashier/payOrderInfo',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}

/** 调起支付接口, 获取订单状态 **/
export function getPayOrderState () {
  return request.request({
    url: '/api/cashier/payOrderState',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}

/** 取消订单 **/
export function cancelPay () {
  return request.request({
    url: '/api/cashier/cancelPay',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}
